import React, { ReactElement, useCallback, useMemo } from "react";
import ReactSelect, { components } from "react-select";
import ReactSelectAsync from "react-select/async";
import clsx from "clsx";

import Container from "../Select3/Container";
import DropdownIndicator from "../Select3/DropdownIndicator";
import MenuList from "../Select3/MenuList";
import { dimensions, palette } from "@/components/Select3/constants";
import ClearIndicator from "@/components/Select/ClearIndicator";
import { SingleValue } from "@/components/Select/components/single-value";

type Props = Parameters<ReactSelectAsync>[0] & {
  error?: string;
  height?: string;
  optionComponent?: (props: any) => JSX.Element;
  leftIcon?: ReactElement;
  required?: boolean;
  smallFontSize?: boolean;
  readOnly?: boolean;
  autocomplete?: boolean;
  menuTranslation?: boolean;
  singleValueComponent?: (props: any) => JSX.Element;
  customStyle?: boolean;
  errorMessage?: string;
};

const Input = (props: any) => <components.Input className="autocomplete-input" {...props} />;
const AutocompleteInput = (props: any) => (
  <components.Input className="autocomplete-input" {...props} isHidden={false} />
);

const Select: React.FC<Props> = React.forwardRef(
  (
    {
      placeholder,
      options,
      loadOptions,
      required = true,
      optionComponent,
      leftIcon,
      isSearchable,
      components,
      error,
      height,
      smallFontSize,
      autocomplete,
      menuTranslation = false,
      readOnly = false,
      singleValueComponent = SingleValue,
      customStyle = false,
      errorMessage,
      ...props
    },
    ref,
  ) => {
    const theme = useCallback((t: any) => {
      return {
        ...t,
        borderRadius: dimensions.borderRadius,
        spacing: {
          ...t.spacing,
          controlHeight: dimensions.height,
        },
        colors: {
          ...t.colors,
          primary: "#555F6D",
          primary25: palette.opacity25,
          primary75: palette.opacity75,
          neutral80: "#E5E9F0",
        },
      };
    }, []);

    const customStyles = useMemo(
      () => ({
        container: (provided: any, state: any) => ({
          ...provided,
          borderRadius: dimensions.containerBorderRadius,
          backgroundColor: palette.bg,
          cursor: state.isDisabled ? "not-allowed" : "initial",
        }),
        control: (provided: any, state: any) => {
          if (errorMessage) {
            return {
              ...provided,
              borderColor: "#FF0000",
              maxHeight: customStyle ? "100px" : dimensions.height,
              backgroundColor: palette.bg,
              outline: "none",
              boxShadow: "none",
              borderWidth: "0.5px",
              cursor: state.isDisabled ? "not-allowed!important" : "initial",
              opacity: state.isDisabled && !readOnly ? "30%" : "initial",
            };
          }
          return {
            ...provided,
            borderColor: state.isFocused ? "#555F6D" : state.isSelected ? "#9EA8B3" : "#D0D6E0",
            maxHeight: customStyle ? "100px" : dimensions.height,
            backgroundColor: palette.bg,
            outline: "none",
            boxShadow: "none",
            borderWidth: "0.5px",
            cursor: state.isDisabled ? "not-allowed!important" : "initial",
            opacity: state.isDisabled && !readOnly ? "30%" : "initial",
          };
        },
        indicatorsContainer: (provided: any) => ({
          ...provided,
          margin: dimensions.indicatorMargin,
        }),
        valueContainer: (provided: any) => ({
          ...provided,
          padding: !leftIcon && dimensions.valueContainerPadding,
          marginTop: customStyle ? "24px" : dimensions.valueContainerMarginTop,
          marginLeft: dimensions.valueContainerMarginLeft,
          paddingLeft: !!leftIcon && "48px",
          paddingRight: !!leftIcon && "16px",
        }),
        input: (provided: any) => ({
          ...provided,
          margin: dimensions.inputMargin,
          fontWeight: dimensions.inputFontWeight,
          fontSize: dimensions.inputFontSize,
        }),
        menu: (provided: any) => {
          if (menuTranslation)
            return {
              ...provided,
              zIndex: "1000",
              marginTop: "-130px",
            };
          return {
            ...provided,
            marginTop: "2px",
            zIndex: "1000",
          };
        },
      }),
      [error, errorMessage],
    );

    return (
      // @ts-ignore
      <div className="group relative " contenteditable="true">
        {leftIcon && (
          <div
            className={clsx(
              "absolute left-2.5 top-2 !bg-transparent",
              "flex h-10 w-10 items-center justify-center bg-white",
              error && "!bg-error-light",
            )}
          >
            {leftIcon}
          </div>
        )}
        <label
          className={clsx(
            "pointer-events-none absolute left-4 z-[1] max-w-[calc(100%-50px)] -translate-y-1/2 align-middle text-textBodySemi text-label transition-all",
            "overflow-hidden text-ellipsis whitespace-nowrap",
            props.inputValue || !!props.value ? "!top-0 !mt-4 !text-textSmall" : "top-1/2 opacity-70",
            "group-focus-within:top-0 group-focus-within:mt-4 group-focus-within:!text-textSmall group-focus-within:text-secondaryText",
            props.isDisabled && !readOnly && "opacity-30",
            leftIcon && "pl-8",
            smallFontSize && "text-textSmallSemi",
            errorMessage && "!text-error",
          )}
        >
          {placeholder}
        </label>
        {loadOptions ? (
          <ReactSelectAsync
            ref={ref}
            placeholder=""
            theme={theme}
            styles={customStyles}
            loadOptions={loadOptions}
            components={{
              ...components,
              SelectContainer: Container,
              IndicatorSeparator: null,
              DropdownIndicator,
              ClearIndicator,
              SingleValue: singleValueComponent,
              MenuList,
              Input: autocomplete ? AutocompleteInput : Input,
              Option: optionComponent ?? DefaultOption,
            }}
            {...props}
          />
        ) : (
          <ReactSelect
            ref={ref}
            placeholder=""
            theme={theme}
            styles={customStyles}
            components={{
              ...components,
              SelectContainer: Container,
              IndicatorSeparator: null,
              DropdownIndicator: readOnly
                ? undefined
                : required
                ? DropdownIndicator
                : !props.value
                ? DropdownIndicator
                : undefined,
              ClearIndicator,
              SingleValue: singleValueComponent,
              MenuList,
              Input: autocomplete ? AutocompleteInput : Input,
              Option: optionComponent ?? DefaultOption,
            }}
            options={options}
            isSearchable={isSearchable || false}
            isClearable={!required}
            {...props}
          />
        )}
        {error && (
          <label className={clsx("text-2xs absolute -bottom-0.5 right-0 translate-y-full leading-3 text-error")}>
            {error}
          </label>
        )}
      </div>
    );
  },
);

export default Select;

const DefaultOption = (props: any) => {
  return (
    <span
      ref={props.innerRef}
      {...props.innerProps}
      className={clsx(
        "flex cursor-pointer items-center bg-white px-4 py-3 text-textBody hover:bg-bgDisabled active:bg-bg",
        {
          "text-secondaryText/50": props.isSelected,
          "text-primaryText": !props.isSelected,
        },
      )}
    >
      {props.selectProps.getOptionLabel(props.data)}
    </span>
  );
};
