import { transformError } from "@/shared";
import { docs } from "@/api/kuber-api";
import {
  AgCustomerDocumentPackageListEntity,
  GenerateOrderInfoFileController_AgOrderIdCodeRequest,
  GenerateOrderInfoFileController_DocsResponse,
  GetCustomerDocumentPackageController_AgCustomerDocumentPackageResponse,
  GetCustomerDocumentPackageListController_AgCustomerDocumentPackageListResponse,
} from "@/api/kuber-api/codegen";
import { DocsResponse, GetDocumentsRequest } from "@/domain/docs";
import { loadBinaryResource } from "@/data/utils";

export async function getDocumentList(request: GetDocumentsRequest): Promise<DocsResponse> {
  try {
    return await docs.api.getDocumentList(request).then((response) => toDocsResponse(response));
  } catch (error) {
    throw transformError(error);
  }
}

function toDocsResponse(
  response: GetCustomerDocumentPackageListController_AgCustomerDocumentPackageListResponse,
): DocsResponse {
  const docs = response.entities ? response.entities : ([] as AgCustomerDocumentPackageListEntity[]);
  return {
    items: docs,
    pagination: response.pagination ? response.pagination : { offset: 0, limit: 10, total: docs.length },
  };
}

export async function getDocumentPackage(request: {
  documentPackageId: string;
}): Promise<GetCustomerDocumentPackageController_AgCustomerDocumentPackageResponse> {
  try {
    return await docs.api.getDocumentPackage(request).then((response) => response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function downloadPdfOrder(
  request: GenerateOrderInfoFileController_AgOrderIdCodeRequest,
): Promise<string | undefined> {
  try {
    const response = await docs.api.downloadPdfOrder(request).then((response) => response);
    const file = await loadBinaryResource(
      response.docs_response_entity?.path?.split(".")[0].split("/")[
        response.docs_response_entity?.path?.split(".")[0].split("/").length - 1
      ] ?? "",
      response.docs_response_entity?.extension?.value ?? "",
    );
    return file;
  } catch (error) {
    throw transformError(error);
  }
}
