import {
  GenerateOrderInfoFileController_AgOrderIdCodeRequest,
  GenerateOrderInfoFileController_DocsResponse,
  GetCustomerDocumentPackageController_AgCustomerDocumentPackageResponse,
  GetCustomerDocumentPackageListController_AgCustomerDocumentPackageListResponse,
} from "@/api/kuber-api/codegen";
import { clientWithAuth } from "@/api/kuber-api";
import { DOWNLOAD_PDF_ORDER, GET_DOCS_LIST, GET_DOCS_PACKAGE } from "@/api/kuber-api/endpoints";
import { GetDocumentsRequest } from "@/domain/docs";

export function getDocumentList(
  request: GetDocumentsRequest,
): Promise<GetCustomerDocumentPackageListController_AgCustomerDocumentPackageListResponse> {
  const url = `${GET_DOCS_LIST}?offset=${request.pagination.offset}&limit=${request.pagination.limit}`;
  return clientWithAuth
    .get<GetCustomerDocumentPackageListController_AgCustomerDocumentPackageListResponse>(url)
    .then((response) => response.data);
}

export function getDocumentPackage(request: {
  documentPackageId: string;
}): Promise<GetCustomerDocumentPackageController_AgCustomerDocumentPackageResponse> {
  const url = GET_DOCS_PACKAGE + request.documentPackageId;
  return clientWithAuth
    .get<GetCustomerDocumentPackageController_AgCustomerDocumentPackageResponse>(url)
    .then((response) => response.data);
}

export function downloadPdfOrder(
  request: GenerateOrderInfoFileController_AgOrderIdCodeRequest,
): Promise<GenerateOrderInfoFileController_DocsResponse> {
  return clientWithAuth.post(DOWNLOAD_PDF_ORDER, request).then((response) => response.data);
}
