import clsx from "clsx";
import React, { ReactElement, useRef, useState } from "react";
import { useClickAway } from "react-use";

const ClickawayTooltipIcon: React.FC<{
  placement: "bottom" | "right" | "top" | "left";
  iconClassName: string;
  tooltipClassName: string;
  icon: ReactElement;
  isCompound?: boolean;
  onClick?: () => void;
  customCloseIcon?: ReactElement;
}> = ({ children, placement, iconClassName, tooltipClassName, icon, onClick, isCompound, customCloseIcon }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const ref = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  const CloseTooltip = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    setIsTooltipOpen(false);
  };

  const OpenTooltip = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsTooltipOpen(true);
  };

  useClickAway(ref, () => {
    setIsTooltipOpen(false);
  });

  return (
    <div
      className={clsx("relative flex items-center justify-center text-textSmall", iconClassName)}
      onClick={(event) => OpenTooltip(event)}
    >
      {icon}
      <div
        className={clsx(
          "",
          {
            hidden: !isTooltipOpen,
            visible: isTooltipOpen,
            "absolute right-[65%] top-[155%] flex w-[320px] translate-x-1/2 cursor-default flex-col gap-2 rounded-lg bg-primaryText p-4 text-white after:absolute after:bottom-full after:left-1/2 after:-ml-[5px] after:border-[8px] after:border-b-primaryText after:border-l-transparent after:border-r-transparent after:border-t-transparent":
              placement === "bottom",
          },
          tooltipClassName,
        )}
        ref={ref}
      >
        <div className="flex flex-col gap-1">
          <div className={clsx("flex w-full items-start justify-between gap-4", {})}>
            {children}
            <div className="cursor-pointer" onClick={CloseTooltip}>
              {customCloseIcon ? (
                customCloseIcon
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M18 6L6 18" stroke="#F2F5F9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M6 6L18 18" stroke="#F2F5F9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              )}
            </div>
          </div>
          {isCompound && (
            <div className="flex w-full justify-end">
              <div
                className="flex items-center gap-2 hover:cursor-pointer"
                onClick={onClick}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <p
                  className={clsx("text-textSmall", {
                    "text-label": isHovered,
                    "text-white": !isHovered,
                  })}
                >
                  Далее
                </p>
                <div className="flex h-6 w-6 items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className={clsx("", {
                      "stroke-label": isHovered,
                      "stroke-white": !isHovered,
                    })}
                  >
                    <path d="M10 18L16 12L10 6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClickawayTooltipIcon;
